import useDarkMode from "../ChatContext/darkMode";
import { RiDeleteBin6Line } from "react-icons/ri";
const NavLinksContainer = ({ chatLog, setChatLog }) => {
  const isDarkMode = useDarkMode()
  return (
    <div className="navLinks" style={{ position: "absolute", bottom: "10px",padding:"0 10px",display:"flex",flexDirection:"column"}}>

      {/* <NavLinks
        svg={
          <RiDeleteBin6Line color={isDarkMode ? "white" : "black"} size={20} />
        }
        text="Clear Conversations"
        setChatLog={setChatLog}
      /> */}

      <div
        className={`sideMenuButton  ${isDarkMode ? "dark" : ""}`}
        // style={{border:"1px solid #000"}}
        onClick={() => {
          setChatLog([]);
        }}
      >
        <RiDeleteBin6Line size={20} />
        <span style={{ paddingLeft: "10px", fontSize: '16px', fontWeight: "400" }}>Clear Conversations</span>
      </div>

    </div>
  );
};

export default NavLinksContainer;

import React, { useEffect, useRef, useState } from "react";
import IntroSection from "./components/IntroSection";
import NavContent from "./components/NavContent";
import { useChatContext } from "./ChatContext/ChatProvider";
import ChatWrapper from "./components/ChatWrapper";
import useDarkMode from "./ChatContext/darkMode";
import { RiSendPlaneFill } from "react-icons/ri";
import { HiMenuAlt2 } from "react-icons/hi";

const Home = () => {
  const isDarkMode = useDarkMode()
  const [showMenu, setShowMenu] = useState(false);
  // const [inputPrompt, setInputPrompt] = useState("");
  // const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);
  const { inputPrompt, setInputPrompt, chatLog, setChatLog, handleSubmit, wordCount, setWordCount,language, setLanguage,articleType, setArticleType } = useChatContext()
  const chatLogEndRef:any = useRef(null);


  useEffect(() => {
    // Scroll to the bottom of the chat log to show the latest message
    if (chatLogEndRef.current) {
      chatLogEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [chatLog]);

  return (
    <>
      <header style={{ backgroundColor: isDarkMode ? "transparent" : "#f7f7f7", color: isDarkMode ? "white" : "black" }}>
        <div className="menu">
          <button style={{ cursor: "pointer" }} onClick={() => setShowMenu(true)}>
            <HiMenuAlt2 color={isDarkMode ? "white" : "black"} size={24} />
          </button>
        </div>
        <h1>Talkbot</h1>
      </header>

      {showMenu && (
        <nav style={{ backgroundColor: isDarkMode ? "rgba(255,255,255,0.5)" : "rgba(0,0,0,0.5)" }}>
          <div className="navItems" style={{ backgroundColor: isDarkMode ? "black" : "#f7f7f7" }}>
            <NavContent
              chatLog={chatLog}
              setChatLog={setChatLog}
              setShowMenu={setShowMenu}
            />
          </div>
          <div className="navCloseIcon">
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#fff"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div>
        </nav>
      )}

      <aside className="sideMenu" style={{ backgroundColor: isDarkMode ? "transparent" : "#f7f7f7" }}>
        <NavContent
          chatLog={chatLog}
          setChatLog={setChatLog}
          setShowMenu={setShowMenu}
        />
      </aside>

      <section className="chatBox" style={{ backgroundColor: isDarkMode ? "#1d1d1d" : "white", color: isDarkMode ? "#d1d5db" : "#21252b", ...(chatLog.length <= 0 ? { display: "flex", justifyContent: "center", alignItems: 'center' } : {}) }}>
        {chatLog.length > 0 ? (
          <ChatWrapper chatLogEndRef={chatLogEndRef} />
        ) : (
          <IntroSection />
        )}

        <form className="promptFormWrapper" onSubmit={handleSubmit}>

          {/* <div className="formInputOptions" style={{ display: "flex" }}>
            <div>
              <input className="inputPrompttWordCount" style={{ backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)", color:isDarkMode?"white":"black"}} type="text" value={wordCount} onChange={(e) => setWordCount(e.target.value)} placeholder="No. of words" />
            </div>
            <DropDown value={language} setValue={setLanguage} options={["English","Hindi"]} />
            <DropDown value={articleType} setValue={setArticleType} options={["Formal","Informal"]} />
          </div> */}
          <div className="inputPromptWrapper" style={{ backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)" }}>

            <input
              name="inputPrompt"
              id=""
              className="inputPrompttTextarea"
              type="text"
              value={inputPrompt}
              onChange={(e) => setInputPrompt(e.target.value)}
              autoFocus
              style={{ color: isDarkMode ? "white" : "black", padding: "0 20px" }}
              placeholder="Say something !!"
              autoComplete="off"
            ></input>
            <button disabled={inputPrompt.length <= 0} className={`submitBtn ${isDarkMode ? "dark" : ""}`} type="submit">
              <RiSendPlaneFill color={!isDarkMode ? "rgba(255, 255, 255)" : "rgba(0, 0, 0)"} size={18} />
            </button>
          </div>

        </form>
      </section>
    </>
  );
};

export default Home;

import { FiEdit } from "react-icons/fi";
import useDarkMode from "../ChatContext/darkMode";

const NewChat = ({ setChatLog, setShowMenu }) => {
  const isDarkMode = useDarkMode()
  return (
    <div
      className={`sideMenuButton  ${isDarkMode?"dark":""}`}
      // style={{border:"1px solid #000"}}
      onClick={() => {
        setChatLog([]);
        setShowMenu(false);
      }}
    >
       <FiEdit size={20} />
      <span style={{paddingLeft:"10px", fontSize:'16px', fontWeight:"400"}}>New chat</span>
    </div>
  );
};

export default NewChat;

import { FC, createContext, useContext, useState } from "react";
import axios from "axios";
import { WithChildren } from "../../../../helpers";
export const ChatContext = createContext<any>(null);

export const ChatContextProvider:FC<WithChildren> = ({ children }) => {
    const [inputPrompt, setInputPrompt] = useState("");
    const [wordCount, setWordCount] = useState(1000);
    const [language, setLanguage] = useState("English");
    const [articleType, setArticleType] = useState("Formal");
    const [chatLog, setChatLog] = useState<any>([]);
    const [err, setErr] = useState(false);
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (inputPrompt.trim() !== "") {
          const newChatLogEntry = { chatPrompt: inputPrompt,wordCount,language, botMessage: "Loading..." };
          setChatLog((prevChatLog:any) => [...prevChatLog, newChatLogEntry]);
    
          setInputPrompt(""); // Clear input after submitting
    
          console.warn("api called");
          try {
            // const response = await axios.post("article/getArticle", {
            //     "topic": inputPrompt.trim(),
            //     "tone": articleType,
            //     "word_count": 100,
            //     "final_data_format": "Conversational Article",
            //     "language": language
            // });
            const response = await axios.post("/chat/get_conversational_chat", {
                "text": inputPrompt.trim(),
            });
            console.warn(response);
            const data = response.data;
            let markdown:string;
            
            if (data.length === 1) {
              const item = data[0];
              let text = '';
              Object.keys(item).forEach(key => {
                text += `**${key}** : ${item[key]}\n`;
              });
              markdown= text; // Plain text representation
            }
            else if(data.length>1){
              // Get the headers dynamically from the keys of the first object
              const headers = Object.keys(data[0]);
            
              // Start the table with dynamic headers
              markdown = `\n| ${headers.join(' | ')} |\n`;
              markdown += `| ${headers.map(() => '---------').join(' | ')} |\n`;
            
              // Add rows for each object in the array
              data.forEach((item:any) => {
                markdown += `| ${headers.map(header => item[header]).join(' | ')} |\n`;
              });
            }else{
                markdown=data.message
            }
            if(data.err){
                markdown="Something went wrong!!!"
            }
    
            // // Update chat log with the new response
            setChatLog((prevChatLog:any) => [
              ...prevChatLog.slice(0, prevChatLog.length - 1), // all entries except the last
              { ...newChatLogEntry, botMessage: markdown }, // update the last entry with the bot's response
            ]);
    
            setErr(false);
          } catch (error:any) {
            setErr(error);
            console.error(error);
          }
        }
      };
    return (
        <ChatContext.Provider value={{
            inputPrompt, setInputPrompt,
            wordCount, setWordCount,
            language, setLanguage,
            chatLog, setChatLog,
            articleType, setArticleType,
            err, setErr,
            handleSubmit
        }}>
            {children}
        </ChatContext.Provider>
    );
};
export const useChatContext = ()=>{return useContext(ChatContext);} 

import React, { useState } from "react";
import Avatar from "../components/Avatar";
import "../styles/ChatWrapperStyles.css"
import { FaRobot } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { useChatContext } from "../ChatContext/ChatProvider";
import useDarkMode from "../ChatContext/darkMode";
import Loading from "./Loading"
import BotMessage from "./BotMessage";
const ChatWrapper = ({ chatLogEndRef }) => {
    const { chatLog, setChatLog } = useChatContext()
    const [err, setErr] = useState(false);
    const isDarkMode = useDarkMode()
    
    return (
        <div className="chatLogWrapper">
            {chatLog.map((chat, idx) => (
                <div className="chatLog" key={chat.chatPrompt} id={`navPrompt-${chat.chatPrompt.replace(/[^a-zA-Z0-9]/g, "-")}-${idx}`}>
                    {/* User message */}
                    <div className="messageContainer">
                        <div className="promptMessageBox">
                            <div className="messageIconBox">
                                <Avatar bg={isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"} className="openaiSVG">
                                    <FaRegUser size={28} color={isDarkMode ? "white" : "black"} />
                                </Avatar>
                            </div>
                            <div className="promptMessage" style={{ backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)", color: isDarkMode ? "white" : "black" }}>{chat.chatPrompt}</div>
                        </div>
                    </div>
                    {/* Bot response */}
                    <div className="messageContainer">
                        <div className="botMessageBox">
                            <div className="messageIconBox">
                                <Avatar bg={"rgba(0,0,0,0.2)"}>
                                    <FaRobot size={23} color={isDarkMode ? "white" : "black"} />
                                </Avatar>
                            </div>
                            {chat.botMessage === "Loading..." ? (
                                <div className="botMessage loading" style={{ color: isDarkMode ? "white" : "black" }}>
                                    <Loading color={isDarkMode?"#fff":"#000"} />
                                </div>
                            ) : err ? (
                                "<Error err={err} />"
                            ) : (
                                <div className="botMessage" style={{ color: isDarkMode ? "white" : "black" }}>
                                    <BotMessage text={chat.botMessage}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
            <div ref={chatLogEndRef} />{" "}
            {/* Invisible element to scroll into view */}
        </div>
    )
}

export default ChatWrapper
import { FC, createContext, useContext, useEffect, useState } from 'react'
import { WithChildren } from '../../helpers'
import { AuwPropertyTypes, CategoryTypeList, CategoryTypes, SegmentTypes } from './_model'
import { LayoutSplashScreen } from '../../layout/core'
import { filterByCategoryType, getAllCategories } from './category'
import { getAllSegments } from './segment'
import axios from 'axios'

export interface AppDataContextModel {
    categoryList?: Array<CategoryTypes>
    loadCategory?:boolean
    setLoadCategory:(_loadCategory:boolean)=>void
    setCategoryList: (_categoryList: Array<CategoryTypes>) => void
    updateCategoryList:()=>void
    categoryTypeList?:CategoryTypeList
    getCategoryListByType:(_type:string)=>void
    segmentList?:Array<SegmentTypes>
    updateSegmentList:()=>void
    setSegmentList:(_segmentList:Array<SegmentTypes>)=>void
    currentSegment?:SegmentTypes | undefined
    setCurrentSegment:(_currentSegment:SegmentTypes|undefined)=>void
    loadSegment?:boolean
    setLoadSegment:(_loadSegment:boolean)=>void;
    currentCategory?:CategoryTypes | undefined
    setCurrentCategory:(_currentSegment:CategoryTypes|undefined)=>void,
    auwProperties?:Array<AuwPropertyTypes>,
    setAuwProperties:(_auwProperties:Array<AuwPropertyTypes>)=>void
}

const AppDataContext = createContext<AppDataContextModel>({
    setCategoryList: (_categoryList: Array<CategoryTypes>) => { },
    setLoadCategory:(_loadCategory:boolean)=>{ },
    updateCategoryList:()=>{},
    getCategoryListByType:(_type:string)=>{ },
    updateSegmentList:()=>{},
    setSegmentList:(_segmentList:Array<SegmentTypes>)=>{},
    setCurrentSegment:(_currentSegment:SegmentTypes|undefined)=>{},
    setLoadSegment:(_loadSegment:boolean)=>{ },
    setCurrentCategory:(_currentCategory:CategoryTypes|undefined)=> {},
    setAuwProperties:(_auwProperties:Array<AuwPropertyTypes>)=>{}
})

const AppDataProvider: FC<WithChildren> = ({ children }) => {
    const [categoryList, setCategoryList] = useState<Array<CategoryTypes>>([])
    const [loadCategory,setLoadCategory] = useState<boolean>(true)
    const [categoryTypeList, setCategoryTypeList] = useState<CategoryTypeList>({ total: [], platform: [], ageGroup: [], contentCategory: [], location: [] });
    const [segmentList, setSegmentList] = useState<Array<SegmentTypes>>([])
    const [loadSegment,setLoadSegment] = useState<boolean>(true)
    const [currentSegment,setCurrentSegment] = useState<SegmentTypes|undefined>()
    const [currentCategory,setCurrentCategory] = useState<CategoryTypes|undefined>()
    const [auwProperties,setAuwProperties] = useState<Array<AuwPropertyTypes>>([]);

    const updateCategoryList = async () => {
        setLoadCategory(loadCategory=>loadCategory=true)
        setCategoryList(categoryList=>categoryList=[])
        const data = await getAllCategories();
        if (data && data.length) {
            setCategoryList(data);
            setCategoryTypeList(filterByCategoryType(data));
            setLoadCategory(false)
        }
        else {
            console.log("No data available")
        }
    }
    const updateSegmentList = async()=>{
        setLoadSegment(loadSegment=>loadSegment=true)
        setSegmentList(segmentList=>segmentList=[])
        const data = await getAllSegments();
        
        if (data && data.length) {
            setSegmentList(data);
            setLoadSegment(false)
        }
        else {
            console.log("No data available")
        }
    }
    const getCategoryListByType = (type:String)=>{
        if(type === "Location"){
            setCategoryList(categoryTypeList.location)
        }
        else if (type === "Platform") {
            setCategoryList(categoryTypeList.platform)
        }
        else if (type === "Age group") {
            setCategoryList(categoryTypeList.ageGroup)
        }
        else if (type === "Content category") {
            setCategoryList(categoryTypeList.contentCategory)
        }
        else {
            setCategoryList(categoryTypeList.total)
        }
    }
    const getAuwProperties = async() =>{
        try {
            const response = await axios.get(`categories/getAuwProperties`);
            const data = response.data
            if (data && data.length) {
                setAuwProperties(data);
            }
            else {
                console.log("No data available")
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(()=>{
        if(!auwProperties.length){
            getAuwProperties()
        }
    },[])
    const value: AppDataContextModel = {
        currentSegment,
        setCurrentSegment,
        categoryList,
        loadCategory,
        setLoadCategory,
        setCategoryList,
        updateCategoryList,
        categoryTypeList,
        getCategoryListByType,
        updateSegmentList,
        segmentList,
        setSegmentList,
        loadSegment,
        setLoadSegment,
        currentCategory,
        setCurrentCategory,
        auwProperties,
        setAuwProperties
    }
    return <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>
}

const CategoryDataInit:FC<WithChildren> = ({children})=>{
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const {updateCategoryList } = useAppData()
    useEffect(()=>{
        try {
            updateCategoryList()
        } catch (error) {
            console.log(error);
        }finally{
            setShowSplashScreen(false)
        }
    },[])
    return showSplashScreen ? <LayoutSplashScreen /> :<>{children}</>
} 
const SegmentDataInit:FC<WithChildren> = ({children})=>{
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const {updateSegmentList } = useAppData()
    useEffect(()=>{
        try {
            updateSegmentList()
        } catch (error) {
            console.log(error);
        }finally{
            setShowSplashScreen(false)
        }
    },[])
    return showSplashScreen ? <LayoutSplashScreen /> :<>{children}</>
} 

function useAppData() {
    return useContext(AppDataContext)
}


export { AppDataProvider, useAppData,CategoryDataInit,SegmentDataInit }

import React, { useRef } from 'react'
import { IoCopyOutline } from "react-icons/io5";
import ReactMarkdown from "react-markdown"
import useDarkMode from '../ChatContext/darkMode';
import remarkGfm from 'remark-gfm';

const BotMessage = ({text}) => {
    const textRef=useRef(null)
    const isDarkMode = useDarkMode()
    const copyText = () => {
        const textToCopy = textRef.current.innerText; // Get text content from the ref
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                console.warn('Text copied to clipboard!'); // Success message
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
                // setCopySuccess('Failed to copy text.'); // Error message
            });
    }
    return (
        <>
            <div className={`markdown-css ${isDarkMode?"dark":""}`} ref={textRef}>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {text}
                </ReactMarkdown>
            </div>
            <div className="response-tools"><button className={`tool-btn ${isDarkMode ? "dark" : ""}`} onClick={copyText}><IoCopyOutline /> Copy</button></div>
        </>
    )
}

export default BotMessage
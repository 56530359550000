import React from "react";
import { CiChat1 } from "react-icons/ci";
import useDarkMode from "../ChatContext/darkMode";

const NavPrompt = ({ idx, chatPrompt, setShowMenu }) => {
  const chatPromptCharacters = chatPrompt.split("");
  const navPromptHref = `navPrompt-${chatPrompt.replace(/[^a-zA-Z0-9]/g, "-")}-${idx}`;
  const isDarkMode = useDarkMode()
  const handlePromptScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    else{
      console.warn(`element with id ${id} not found`);
    }
  }
  return (
    <div className={`navPrompt ${isDarkMode ? "dark" : ""}`}>
      <a style={{ color: isDarkMode ? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.8)" }} href={`#${navPromptHref}`} onClick={(e) => {
        e.preventDefault();
        handlePromptScroll(`#${navPromptHref}`)
      }}>
        <CiChat1 size={20} color={isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)"} />
        <p>
          {chatPromptCharacters.map((char, idx) => (
            <span key={idx} style={{ animationDelay: `${idx * 0.1}s` }}>
              {char}
            </span>
          ))}
        </p>
      </a>
    </div>
  );
};

export default NavPrompt;

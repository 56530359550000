import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

const Loading = ({color="#000"}) => {
  const override = {
    loading: true,
  };

  return (
    <div>
      <PulseLoader
        color={color}
        loading={override.loading}
        cssOverride={override}
        size={5}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loading;

import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { PageDataProvider } from './core'
import { reInitMenu } from '../helpers'
import { AddSegmentModal } from '../partials/modals/add-segment/AddSegmentModal'
import { AddCategoryModal } from '../partials/modals/add-category/AddCategoryModal'
import { AppDataProvider } from '../components/appcontroller/AppData'
import { HeaderWrapper } from '../components/header/HeaderWrapper'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])
  return (
    <PageDataProvider>
      <AppDataProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid' style={{position:"relative"}}>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddSegmentModal />
        <AddCategoryModal />
      </AppDataProvider>
    </PageDataProvider>
  )
}

export default MasterLayout
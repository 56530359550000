import "./styles/normal.css";
import "./styles/Home.css"
import Home from "./Home";
import { ChatContextProvider } from "./ChatContext/ChatProvider";
import useDarkMode from "./ChatContext/darkMode";

const ChatBotWrapper = () => {
    const isDarkMode = useDarkMode()
    return (
        <div className="chatApp" style={{ backgroundColor: isDarkMode ? "#121212" : "#ffffff", color: isDarkMode ? "white" : "black" }}>
            <ChatContextProvider>
                <Home />
            </ChatContextProvider>
        </div>
    )
}

export default ChatBotWrapper
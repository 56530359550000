
import React, { FC, useEffect, useState } from 'react'
import "../ModalStyles.css"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAppData } from '../../../components/appcontroller/AppData';
import { saveCategory } from '../../../components/appcontroller';
import { IoMdClose } from "react-icons/io";
import Select, { OptionProps, SingleValue } from 'react-select';
type MyOption = {
  label: string;
  value: any;
};
export interface ICatgeory {
  parent_category: string | null,
  label: string,
  type: string,
  slug: string | null,
  propertyId: string
}
export const categoryInitValues: ICatgeory = {
  parent_category: '',
  label: '',
  type: '',
  slug: '',
  propertyId: '1'
};
const profileDetailsSchema = Yup.object().shape({
  parent_category: Yup.string().nullable(),
  label: Yup.string().required('Label is required'),
  type: Yup.string().required('Type is required'),
  slug: Yup.string().nullable(),
  propertyId: Yup.string().required('Property name is required'),
})
const typeOptions = [
  { label: "PLATFORM", value: "PLATFORM" },
  { label: "AGE_GROUP", value: "AGE_GROUP" },
  { label: "CONTENT_CATEGORY", value: "CONTENT_CATEGORY" },
  { label: "LOCATION", value: "LOCATION" },
]
const AddCategoryModal: FC = () => {
  const { updateCategoryList, auwProperties } = useAppData()
  const [propertyOptions, setPropertyOptions] = useState<Array<any>>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');
  useEffect(() => {
    if (auwProperties?.length && !propertyOptions.length) {
      let options: Array<any> = []
      auwProperties.forEach(property => {
        let label = property.notification_topic[0].toUpperCase() + property.notification_topic.slice(1);
        options.push({ label: label, value: property.id })
      })
      setPropertyOptions(options)
    }
  }, [auwProperties])


  const formik = useFormik<ICatgeory>({
    initialValues: categoryInitValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values,{setStatus,resetForm}) => {
      setError('')
      setLoading(true)
      try {
        const data = await saveCategory(values);
        if (data.error) {
          setStatus({type:'failed',message:data.message})
          console.log(data.error);
        }else{
          resetForm({values:categoryInitValues})
          setStatus({type:'success',message:data.message})
          updateCategoryList()
        }
        setTimeout(() => {
          setStatus(null)
        }, 2000);
      } catch (error) {
        console.log(error);
      }
      setLoading(false)
    },
  })

  return (
    <div className='modal fade' id='kt_modal_add_category' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 position-relative justify-content-center'>
            <div className='text-center mb-5'>
              <h1 className='mb-3'>Add new Category</h1>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary close-btn' data-bs-dismiss='modal'>
              <IoMdClose size={20} />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='separator d-flex flex-center mb-2'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'></span>
            </div>
            {formik.status && <>{formik.status?.type === 'failed' ? (
              <div className='mb-lg-1 alert alert-danger'>
                <div className='alert-text fw-bold text-center'>{formik.status.message}</div>
              </div>
            ) : (
              <div className='mb-lg-1 alert alert-success'>
                <div className='alert-text fw-bold text-center'>
                  Category added successfully
                </div>
              </div>
            )}</>}
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body py-2'>
                
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label required fw-bold fs-6'>Category label</label>

                  <div className='col-lg-12'>
                    <input
                      type='text'
                      className='modal-form-control mb-3 mb-lg-0'
                      placeholder='Category label'
                      {...formik.getFieldProps('label')}
                    />
                    {formik.touched.label && formik.errors.label && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.label}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>Parent category</label>

                  <div className='col-lg-12'>
                    <input
                      type='text'
                      className='modal-form-control mb-3 mb-lg-0'
                      placeholder='Parent category'
                      {...formik.getFieldProps('parent_category')}
                    />
                    {formik.touched.parent_category && formik.errors.parent_category && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.parent_category}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>Property name</label>

                  <div className='col-lg-12'>
                    <Select options={propertyOptions} onChange={(option: SingleValue<MyOption>) => {
                      formik.setFieldValue('propertyId', option?.value)
                    }} onBlur={() => formik.setFieldValue('propertyId', formik.values.propertyId)} />
                    {formik.touched.propertyId && formik.errors.propertyId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.propertyId}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label required fw-bold fs-6'>Category type</label>

                  <div className='col-lg-12 fv-row'>
                    <Select
                      options={typeOptions} onChange={(option: SingleValue<MyOption>) => {
                        formik.setFieldValue('type', option?.value)
                      }}
                    />
                    {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.type}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>Slug</label>

                  <div className='col-lg-12'>
                    <input
                      type='text'
                      className='modal-form-control mb-3 mb-lg-0'
                      placeholder='Slug'
                      {...formik.getFieldProps('slug')}
                    />
                    {formik.touched.slug && formik.errors.slug && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.slug}</div>
                      </div>
                    )}
                  </div>
                </div>

              </div>
              <div className='row mb-2 mt-2'>
                <div className='col-lg-12'>
                  <button type='submit' className='btn btn-primary col-lg-12' disabled={loading}>
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  )
}

export { AddCategoryModal }
